import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import SocialLinks from "../../components/social_links"

const HoortestPage = () => (
  <Layout>
    <Seo title="Gehoorbescherming en hoortest" />
    <section className="page-header">
      <h1>Gratis hoortest</h1>
    </section>    
    <section className="page-intro">
      <div className="page-intro-content">
        <p>Heeft u steeds meer moeite om gesprekken te volgen? Klinken sommige geluiden niet meer zoals voorheen? Net zoals u regelmatig uw ogen laat testen, zou ook een hoortest deel van uw jaarlijkse gezondheidscontrole moeten uitmaken.</p>
        <p>Indien u twijfels heeft over uw gehoor, dan kan u gemakkelijk via ons uw gratis hoortest boeken.</p>
        <p className="text-right">
          <SocialLinks iconsOnly={true} wrapped={false} />
        </p>
        <Link to="/contact#afspraak" className="button bg-dl-orange hover:bg-black text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
      </div>
      <div className="col-start-8 lg:col-start-6 col-end-13 lg:col-end-12 row-start-1 row-end-1 z-10 h-2/4 md:h-full flex justify-end items-end">
        <StaticImage
            src="../../images/hoortest_hero.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Gehoorbescherming en hoortest"
            placeholder="blurred"
            className="inline-block mx-2 w-12/12 h-full min-h-full"
        />
      </div>
      <div className="section-bg-right col-start-9 lg:col-start-7 col-end-13 -top-16 -right-6 lg:right-4 h-full"></div>
    </section>

    <section className="section my-10">
      <div className="section-content-right">
        <h2 className="pb-8">
          Hoortoestellen
        </h2>
        <p>U kan bij ons terecht voor verschillende soorten hoortoestellen, afhankelijk van het type en de mate van uw gehoorverlies, uw levensstijl en uw budget.</p>
        <p>Samen met u gaat een audioloog op zoek naar een hooroplossing die het beste bij u past. U mag steeds een hoortoestel gedurende 30 dagen gratis en vrijblijvend uitproberen bij u thuis.</p>
        <p className="text-right">
          <SocialLinks iconsOnly={true} wrapped={false} />
        </p>
        <Link to="/contact#afspraak" className="button bg-dl-orange hover:bg-black text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
      </div>
      <div className="col-start-1 col-end-7 row-start-1 row-end-1 z-10 h-full flex justify-start items-center lg:items-end">
        <StaticImage
            src="../../images/hoortest_1.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Hoortoestellen"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 h-1/2 lg:h-4/6"
        />
      </div>
      <div className="section-bg-left"></div>
    </section>

    <section className="section">
      {/* <div className="section-content-left col-end-13 md:col-end-10 lg:col-end-10 xl:col-end-8"> */}
      <div className="section-content-left md:col-end-7">
        <h2 className="pb-8">
          Gehoorbescherming op maat
        </h2>
        <p>Tegenwoordig hebben steeds meer mensen last van gehoorschade ten gevolge van een te luide omgeving. Gelukkig bestaan er verschillende oplossingen om uw gehoor eenvoudig te beschermen. </p>
        <p>Het ene paar oordoppen is echter het andere niet. Daarom vindt u bij ons gehoorbescherming – universele oordoppen of op maat gemaakt –  die u kunt dragen bij het luisteren naar luide muziek, tijdens het zwemmen, voor het klussen, etc.</p>
        <p className="text-right">
          <SocialLinks iconsOnly={true} wrapped={false} />
        </p>
        <Link to="/contact#afspraak" className="button bg-dl-orange hover:bg-black text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
      </div>
      <div className="col-start-7 col-end-13 row-start-1 row-end-1 z-10 h-full flex justify-end items-start lg:items-center">
        <StaticImage
            src="../../images/oordoppen-op-maat.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Gehoorbescherming op maat"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 md:h-1/2 lg:h-2/3"
        />
      </div>
      <div className="section-bg-right col-start-8 md:col-start-8 -top-6 md:top-10"></div>
    </section>

  </Layout>
)

export default HoortestPage
